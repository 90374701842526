@import url(https://fonts.googleapis.com/css?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ewert|Glass+Antiqua&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

body {
  background-color: #003d3a; }
  body:after {
    background: url("/img/bgBody.jpg");
    background-repeat: repeat;
    background-size: contain;
    content: '';
    -webkit-filter: hue-rotate(150deg);
            filter: hue-rotate(150deg);
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1; }

.social-icon circle {
  fill: rgba(0, 0, 0, 0.25); }

.carousel .thumb img {
  height: 100% !important;
  width: 100% !important; }

.carousel .slide {
  max-height: 50vmin;
  /* change this to whatever you want */
  width: auto; }

