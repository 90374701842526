@import url('https://fonts.googleapis.com/css?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ewert|Glass+Antiqua&display=swap');
// @mixin gradient($direction, $list) {
// 	background: -webkit-linear-gradient($direction, $list);
// }

// $breakpoints: ("tablet": 600px);
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// *{
// 	box-sizing: border-box;
// }
body{
	background-color: #003d3a;
	// font-family: 'Work Sans', sans-serif;
	&:after{
		background: url('/img/bgBody.jpg');
		background-repeat: repeat;
		background-size: contain;
		content: '';
		filter: hue-rotate(150deg);
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: -1;
	}
}
header.MuiAppBar-root {
	// background: none;
	// box-shadow: none;
	// &:after{
	// 	background: url('/img/bgBody.jpg');
	// 	background-position: 0 50%;
	// 	background-repeat: repeat;
	// 	background-size: cover;
	// 	content: '';
	// 	filter: hue-rotate(150deg);
	// 	height: 100%;
	// 	left: 0;
	// 	opacity: 0.1;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: -1;
	// }
}
.social-icon{
	circle{
		fill: rgba(0,0,0,0.25);
	}
}
.carousel .thumb img {
	height: 100% !important;
	width: 100% !important;
}

.carousel .slide {
	max-height: 50vmin;  /* change this to whatever you want */
	width: auto;
}
